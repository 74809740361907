import { apiFetch } from './common';


export const getTreatments = () => {
    return apiFetch(
        'GET',
        '/api/v1/treatments/',
        null,
        false
    ).then((res) => {
        return res.json();
    });
}

export const getClinics = (params) => {
    const urlParams = new URLSearchParams(params).toString();
    return apiFetch(
        'GET',
        '/api/v1/clinics/?' + urlParams,
        null,
        false
    ).then((res) => {
        return res.json();
    });
}

export const getClinic = (id) => {
    return apiFetch(
        'GET',
        '/api/v1/clinics/' + id,
        null,
        false
    ).then((res) => {
        return res.json();
    });
}
