/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Award13 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`award-13 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_272_8648)">
        <path
          className="path"
          d="M5.47398 9.26033L4.66732 15.3337L8.00065 13.3337L11.334 15.3337L10.5273 9.25366M12.6673 5.33366C12.6673 7.91099 10.578 10.0003 8.00065 10.0003C5.42332 10.0003 3.33398 7.91099 3.33398 5.33366C3.33398 2.75633 5.42332 0.666992 8.00065 0.666992C10.578 0.666992 12.6673 2.75633 12.6673 5.33366Z"
          stroke="#B3B3B3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_272_8648">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
