/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const ThumbsUp11 = ({
  color = "#2C2C2C",
  className,
}: Props): JSX.Element => {
  return (
    <svg
      className={`thumbs-up-11 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_272_7979)">
        <path
          className="path"
          d="M4.66634 7.33301L7.33301 1.33301C7.86344 1.33301 8.37215 1.54372 8.74722 1.91879C9.12229 2.29387 9.33301 2.80257 9.33301 3.33301V5.99967H13.1063C13.2996 5.99749 13.4911 6.03734 13.6674 6.11649C13.8437 6.19563 14.0008 6.31216 14.1276 6.45802C14.2544 6.60387 14.348 6.77555 14.4019 6.96117C14.4558 7.14679 14.4687 7.34191 14.4397 7.53301L13.5197 13.533C13.4715 13.8509 13.31 14.1407 13.0649 14.349C12.8199 14.5573 12.5079 14.67 12.1863 14.6663H4.66634M4.66634 7.33301V14.6663M4.66634 7.33301H2.66634C2.31272 7.33301 1.97358 7.47348 1.72353 7.72353C1.47348 7.97358 1.33301 8.31272 1.33301 8.66634V13.333C1.33301 13.6866 1.47348 14.0258 1.72353 14.2758C1.97358 14.5259 2.31272 14.6663 2.66634 14.6663H4.66634"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_272_7979">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

ThumbsUp11.propTypes = {
  color: PropTypes.string,
};
