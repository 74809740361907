import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Award3 } from "../../icons/Award3";
import { Check } from "../../icons/Check";
import { ChevronDown12 } from "../../icons/ChevronDown12";
import { ChevronLeft1 } from "../../icons/ChevronLeft1";
import { ChevronRight7 } from "../../icons/ChevronRight7";
import { Columns4 } from "../../icons/Columns4";
import { Globe2 } from "../../icons/Globe2";
import { Heart4 } from "../../icons/Heart4";
import { Info } from "../../icons/Info";
import { MapPin11 } from "../../icons/MapPin11";
import { Maximize2 } from "../../icons/Maximize2";
import { MessageSquare } from "../../icons/MessageSquare";
import { Plus } from "../../icons/Plus";
import { Star26 } from "../../icons/Star26";
import { Star66 } from "../../icons/Star66";
import { ThumbsDown } from "../../icons/ThumbsDown";
import { ThumbsUp } from "../../icons/ThumbsUp";
import { ThumbsUp11 } from "../../icons/ThumbsUp11";
import image from '../../images/image.png';
import image1 from '../../images/image-1.png';
import image2 from '../../images/image-2.png';
import image3 from '../../images/image-3.png';
import frame363 from '../../images/frame-363.svg';
import frame3631 from '../../images/frame-363-1.svg';
import frame3632 from '../../images/frame-363-2.svg';
import frame3633 from '../../images/frame-363-3.svg';
import rectangle5 from '../../images/rectangle-5.png';
import "./style.css";
import {useParams} from "react-router-dom";
import { getClinic } from '../../api/clinics';

export const Clinic = () => {
    const {id} = useParams();
    console.log(id); //101
    const [clinic, setClinic] = useState(null);

    useEffect(() => {
        getClinic(id)
            .then((res) => {
                setClinic(res);
            });
    }, []);

  return (
    <div className="clinic">
      <div className="frame">

            {clinic?.images && clinic.images[0] && <img className="rectangle" alt="Image" src={clinic.images[0]} />}
            {clinic?.images && clinic.images.length > 1 &&
                <div className="div">{clinic?.images?.map((image, index) => {
                if (index == 0 || index >= 5)
                    return null;
                let className = 'image';
                return <img className={className} alt="Image" src={image} />
            })}</div>}

      {/* <div className="button">
          <Plus className="icon-instance-node" />
          <div className="text-wrapper">Show all photos</div>
        </div> */}
      </div>

      <div className="frame-2">
        <div className="button">
          <ChevronLeft1 className="icon-instance-node" />
          <Link to="/clinics"><div className="text-wrapper">Back</div></Link>
        </div>

        <div className="frame-3">
          <div className="button-2">
            <Columns4 className="icon-instance-node" color="#1E1E1E" />
            <div className="text-wrapper">Compare</div>
          </div>

          <div className="icon-button">
            <Heart4 className="icon-instance-node-2" color="#1E1E1E" />
          </div>
        </div>
      </div>

      <div className="frame-4">
        <div className="text-wrapper-2">Warsaw, Poland</div>

        <div className="text-wrapper-3">{clinic?.name}</div>
      </div>

      <div className="frame-5">
        <div className="frame-6">
          <div className="frame-7">
            <div className="text-wrapper-4 heading about-this-clinic">About this clinic</div>

            <div className="frame-8">
              <div className="frame-9" style={{ whiteSpace: 'pre-wrap' }}>
                {clinic?.description}

                <div className="frame-10">
                  <div className="frame-11">
                    <Globe2 className="icon-instance-node" />
                    <div className="frame-12">
                      <div className="text-wrapper-6">{clinic?.website}</div>
                    </div>
                  </div>

                  <div className="frame-11">
                    <MessageSquare className="icon-instance-node" />
                    <div className="frame-12">
                      <p className="text-wrapper-7">
                        <div className="english-german-polish body-small">{clinic?.languages ? 'Doctors speak ' + clinic.languages?.join(', ') : 'Languages info not available'}</div>

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="frame-7">
            <div className="text-wrapper-4">Highlights</div>

            <div className="frame-13">
              <div className="frame-14">
            {clinic?.highlights ? clinic.highlights.map((item) => (
            <div className="frame-15">
                  <ThumbsUp className="icon-instance-node" />
                  <div className="frame-12">
                    <p className="text-wrapper-8">
                      Premium location · Great for vacation stays
                    </p>

                    <p className="text-wrapper-9">
                      Conveniently situated near attractions, making your dental
                      journey enjoyable.
                    </p>
                  </div>
                </div>)) : 'No highlights'}
              </div>

              <div className="frame-10">
                {clinic?.facilities?.map((facility) => (
                <div className="frame-11">
                  <Check className="icon-instance-node" />
                  <div className="frame-12">
                    <div className="text-wrapper-5">
                        {facility}
                    </div>
                  </div>
                </div>))}

              </div>
            </div>
          </div>

          <div className="frame-7">
            <div className="text-wrapper-4">Accreditations</div>

            <div className="frame-13">
              <div className="frame-14">
                {clinic?.accreditions ? clinic.accreditions.map((accredition) => (
                    <div className="frame-17">
                        <Award3 className="icon-instance-node" />
                        <div className="frame-12">
                            <p className="text-wrapper-8">
                                {accredition}
                            </p>
                            <p className="text-wrapper-10">
                              Recognized for meeting the highest standards in dental
                              care quality and patient safety.
                            </p>

                            <div className="text-wrapper-11">Learn more</div>
                        </div>
                    </div>)) : 'No accreditions'}

              </div>
            </div>
          </div>

          <div className="frame-19">
            <div className="text-wrapper-4">Nearby Accomodation</div>

            <div className="frame-20">
              <div className="list-item">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="frame-21">
                      <Star66 className="icon-instance-node-3" color="white" />
                      <div className="text-wrapper-12">4.6</div>
                    </div>
                  </div>
                </div>

                <div className="frame-22">
                  <div className="text-wrapper-13">Warsaw, Poland</div>

                  <div className="text-wrapper-14">Hilton Warsaw</div>
                </div>

                <div className="frame-23">
                  <div className="text-wrapper-15">5 min drive to</div>

                  <div className="text-wrapper-16">Warsaw Dental Clinic</div>
                </div>

                <div className="frame-24">
                  <div className="text-wrapper-17">More on booking.com</div>

                  <ChevronRight7 className="icon-instance-node-3" />
                </div>
              </div>

              <div className="list-item">
                <div className="overlap-group-wrapper">
                  <div className="frame-wrapper">
                    <div className="frame-21">
                      <Star66 className="icon-instance-node-3" color="white" />
                      <div className="text-wrapper-12">4.6</div>
                    </div>
                  </div>
                </div>

                <div className="frame-22">
                  <div className="text-wrapper-13">Warsaw, Poland</div>

                  <div className="text-wrapper-14">City Center Inn</div>
                </div>

                <div className="frame-23">
                  <div className="text-wrapper-15">5 min drive to</div>

                  <div className="text-wrapper-16">Warsaw Dental Clinic</div>
                </div>

                <div className="frame-24">
                  <div className="text-wrapper-17">More on booking.com</div>

                  <ChevronRight7 className="icon-instance-node-3" />
                </div>
              </div>

              <div className="list-item">
                <div className="overlap-group-wrapper">
                  <div className="div-wrapper">
                    <div className="frame-21">
                      <Star66 className="icon-instance-node-3" color="white" />
                      <div className="text-wrapper-12">4.6</div>
                    </div>
                  </div>
                </div>

                <div className="frame-22">
                  <div className="text-wrapper-13">Warsaw, Poland</div>

                  <div className="hilton-warsaw">Charming Warsaw Suites</div>
                </div>

                <div className="frame-23">
                  <div className="text-wrapper-15">5 min drive to</div>

                  <div className="text-wrapper-16">Warsaw Dental Clinic</div>
                </div>

                <div className="frame-24">
                  <div className="text-wrapper-17">More on booking.com</div>

                  <ChevronRight7 className="icon-instance-node-3" />
                </div>
              </div>

              <button className="button-3">
                <button className="button-4">Show more</button>
              </button>
            </div>
          </div>

          <div className="frame-25">
            <div className="text-wrapper-18">Patient Reviews</div>

            <div className="frame-26">
              <div className="frame-27">
                <div className="frame-28">
                  <div className="frame-29">
                    <div className="text-wrapper-19">4.7</div>
                  </div>

                  <div className="frame-30">
                    <div className="text-wrapper-20">Excellent</div>

                    <p className="based-on-reviews">
                      <span className="span">Based on 17 reviews  on </span>

                      <span className="text-wrapper-21">TrustPilot</span>
                    </p>
                  </div>
                </div>

                <div className="frame-31">
                  <div className="frame-32">
                    <div className="frame-33">
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <div className="text-wrapper-22">5</div>
                    </div>

                    <img className="img" alt="Frame" src={frame363} />
                  </div>

                  <div className="frame-32">
                    <div className="frame-33">
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <div className="text-wrapper-22">4</div>
                    </div>

                    <img
                      className="img"
                      alt="Frame"
                      src={frame3631}
                    />
                  </div>

                  <div className="frame-32">
                    <div className="frame-33">
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <div className="text-wrapper-22">3</div>
                    </div>

                    <img
                      className="img"
                      alt="Frame"
                      src={frame3632}
                    />
                  </div>

                  <div className="frame-32">
                    <div className="frame-33">
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <div className="text-wrapper-22">2</div>
                    </div>

                    <img
                      className="img"
                      alt="Frame"
                      src={frame3633}
                    />
                  </div>

                  <div className="frame-32">
                    <div className="frame-33">
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <div className="text-wrapper-22">1</div>
                    </div>

                    <img
                      className="img"
                      alt="Frame"
                      src={frame3631}
                    />
                  </div>
                </div>
              </div>

              <div className="frame-34">
                <div className="frame-35">
                  <div className="text-wrapper-23">AI Summary</div>

                  <p className="text-wrapper-24">
                    Lorem ipsum dolor sit amet consectetur adipiscing enim ad
                    lorem ipsum dolor sit amet consectetur adipiscing enim ad
                    lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
                    consectetur adipiscing enim ad lorem ipsum dolor sit.
                  </p>
                </div>

                <div className="frame-36">
                  <div className="frame-37">
                    <div className="frame-10">
                      <div className="frame-38">
                        <ThumbsUp11
                          className="icon-instance-node-3"
                          color="#757575"
                        />
                        <div className="text-wrapper-25">Surgeons</div>
                      </div>

                      <div className="frame-38">
                        <ThumbsUp11
                          className="icon-instance-node-3"
                          color="#757575"
                        />
                        <div className="text-wrapper-25">Equipment</div>
                      </div>

                      <div className="frame-38">
                        <ThumbsUp11
                          className="icon-instance-node-3"
                          color="#757575"
                        />
                        <div className="text-wrapper-25">
                          Multilingual staff
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-39">
                    <div className="frame-10">
                      <div className="frame-38">
                        <ThumbsDown className="icon-instance-node-3" />
                        <div className="text-wrapper-25">Room view</div>
                      </div>

                      <div className="frame-38">
                        <ThumbsDown className="icon-instance-node-3" />
                        <div className="text-wrapper-25">Loud halls</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="frame-40">
                <div className="frame-41">
                  <div className="frame-42">
                    <div className="frame-43">
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                    </div>

                    <p className="via-trustpilot">
                      <span className="text-wrapper-26">via </span>

                      <span className="text-wrapper-21">TrustPilot</span>
                    </p>
                  </div>

                  <p className="text-wrapper-27">
                    I had an excellent experience at Smile Care Clinic. The
                    staff was incredibly welcoming, and the consultation was
                    thorough. My dental implants look and feel amazing!”
                  </p>

                  <div className="frame-44">
                    <div className="text-wrapper-28">Anna P.</div>

                    <div className="frame-45">
                      <div className="frame-46">
                        <MapPin11 className="icon-instance-node-3" />
                        <p className="text-wrapper-25">
                          Istanbul Aesthetic Center in Turkey
                        </p>
                      </div>

                      <div className="frame-46">
                        <div className="ellipse" />

                        <div className="text-wrapper-25">Dental implants</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="frame-41">
                  <div className="frame-42">
                    <div className="frame-43">
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                    </div>

                    <p className="via-trustpilot">
                      <span className="text-wrapper-26">via </span>

                      <span className="text-wrapper-21">TrustPilot</span>
                    </p>
                  </div>

                  <p className="text-wrapper-27">
                    I had an excellent experience at Smile Care Clinic. The
                    staff was incredibly welcoming, and the consultation was
                    thorough. My dental implants look and feel amazing!”
                  </p>

                  <div className="frame-44">
                    <div className="text-wrapper-28">Anna P.</div>

                    <div className="frame-45">
                      <div className="frame-46">
                        <MapPin11 className="icon-instance-node-3" />
                        <p className="text-wrapper-25">
                          Istanbul Aesthetic Center in Turkey
                        </p>
                      </div>

                      <div className="frame-46">
                        <div className="ellipse" />

                        <div className="text-wrapper-25">Dental implants</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="frame-41">
                  <div className="frame-42">
                    <div className="frame-43">
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star26
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                      <Star66
                        className="icon-instance-node-3"
                        color="#757575"
                      />
                    </div>

                    <p className="via-trustpilot">
                      <span className="text-wrapper-26">via </span>

                      <span className="text-wrapper-21">TrustPilot</span>
                    </p>
                  </div>

                  <p className="text-wrapper-27">
                    I had an excellent experience at Smile Care Clinic. The
                    staff was incredibly welcoming, and the consultation was
                    thorough. My dental implants look and feel amazing!”
                  </p>

                  <div className="frame-44">
                    <div className="text-wrapper-28">Anna P.</div>

                    <div className="frame-45">
                      <div className="frame-46">
                        <MapPin11 className="icon-instance-node-3" />
                        <p className="text-wrapper-25">
                          Istanbul Aesthetic Center in Turkey
                        </p>
                      </div>

                      <div className="frame-46">
                        <div className="ellipse" />

                        <div className="text-wrapper-25">Dental implants</div>
                      </div>
                    </div>
                  </div>
                </div>

                <button className="button-5">
                  <button className="button-4">Show more</button>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="frame-47">
          <div className="frame-48">
            <div className="overlap-wrapper">
              <div className="overlap">
                <div className="maximize-wrapper">
                  <Maximize2 className="icon-instance-node-2" />
                </div>

                <div className="group">
                  <div className="frame-49">
                    <div className="rectangle-wrapper">
                      <img
                        className="rectangle-2"
                        alt="Rectangle"
                        src="/img/image-28.png"
                      />
                    </div>
                  </div>

                  <div className="overlap-group-2">
                    <img className="line" alt="Line" src="/img/line-19.svg" />

                    <div className="ellipse-2" />
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-50">
              <div className="frame-51">
                <div className="text-wrapper-25">Treatment duration</div>
              </div>

              <div className="group-2">
                <div className="frame-52">
                  <div className="frame-14">
                    <div className="frame-53">
                      <div className="frame-54">
                        <div className="text-wrapper-29">
                          Approx. treatment duration
                        </div>

                        <Info className="info-1" />
                      </div>

                      <div className="text-wrapper-29">2 hours</div>
                    </div>

                    <div className="frame-53">
                      <div className="frame-54">
                        <div className="text-wrapper-29">
                          Approx. recovery period
                        </div>

                        <Info className="info-1" />
                      </div>

                      <div className="text-wrapper-29">2 nights</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-55">
              <div className="text-wrapper-25">Prices starting at</div>

              <div className="text-wrapper-30">$8,999</div>
            </div>

            <div className="frame-56">
              <div className="frame-51">
                <div className="text-wrapper-25">Selected Treatment</div>
              </div>

              <div className="frame-57">
                <div className="frame-46">
                  <div className="ellipse-3" />

                  <div className="text-wrapper-29">Dental implants</div>

                  <ChevronDown12
                    className="icon-instance-node-3"
                    color="#B3B3B3"
                  />
                </div>
              </div>
            </div>

            <img className="line-2" alt="Line" src="/img/line-18.svg" />

            <button className="button-6">
              <button className="button-7">See booking options</button>
            </button>
          </div>
        </div>
      </div>

    </div>
  );
};
