import { Link } from 'react-router-dom';
import { Columns4 } from "../icons/Columns4";
import { Heart4 } from "../icons/Heart4";
import './Navbar.css';

const Navbar = () => {
    return (
        <div className="frame-6 navbar">
            <div className="frame-447">
              <div className="frame-619">
                <div className="tripmd geist-bold-eerie-black-18px">
                  <span className="span geist-bold-eerie-black-18px">TRIP</span
                  ><span className="span geist-bold-eerie-black-18px">(</span
                  ><span className="span geist-bold-eerie-black-18px">MD</span
                  ><span className="span geist-bold-eerie-black-18px">)</span>
                </div>
              </div>
              <div className="navigation-pill-list">
                <div className="navigation-pill">
                  <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px"><Link to="/clinics">Treatments</Link></div>
                </div>
                <div className="navigation-pill">
                  <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px"><Link to="/clinics">Destinations</Link></div>
                </div>
                <div className="navigation-pill">
                  <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">About us</div>
                </div>
                <div className="navigation-pill">
                  <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Become a partner</div>
                </div>
              </div>
              <div className="frame-610">
                <div className="frame-609">
                    <Columns4 className="icon-instance-node-17" color="#000" />
                </div>
                <div className="frame-610-1 frame-610-3">
                    <Heart4 className="icon-instance-node-17" color="#000" />
                </div>
              </div>
            </div>
          </div>
    );
}

export default Navbar;
