/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Award1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`award-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_206_69015)">
        <path
          className="path"
          d="M10.947 18.5197L9.33366 30.6663L16.0003 26.6663L22.667 30.6663L21.0537 18.5063M25.3337 10.6663C25.3337 15.821 21.155 19.9997 16.0003 19.9997C10.8457 19.9997 6.66699 15.821 6.66699 10.6663C6.66699 5.51168 10.8457 1.33301 16.0003 1.33301C21.155 1.33301 25.3337 5.51168 25.3337 10.6663Z"
          stroke="#51617B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_206_69015">
          <rect className="rect" fill="white" height="32" width="32" />
        </clipPath>
      </defs>
    </svg>
  );
};
