import { useState, useEffect } from 'react';
import { Footer } from '../../components/Footer';
import './style.css';
import { ChevronDown12 } from "../../icons/ChevronDown12";
import GridIcon from '../../images/grid.svg';
import ListIcon from '../../images/list.svg';
import { Heart4 } from "../../icons/Heart4";
import { Columns4 } from "../../icons/Columns4";
import { ThumbsUp } from '../../icons/ThumbsUp';
import { Award13 } from '../../icons/Award13';
import { MessageSquare1 } from '../../icons/MessageSquare1';
import { ClinicListItem } from '../../components/ClinicListItem';
import { getClinics } from '../../api/clinics';

export const ClinicsList = () => {
    const perPageContent = 20;
    const [clinics, setClinics] = useState([]);
    const [limit, setLimit] = useState(perPageContent);
    const [offset, setOffset] = useState(0);
    const [showMore, setShouldShowMore] = useState(false);

    const getClinicsList = () => {
        let params = {
            limit,
            offset
        }
        getClinics(params)
            .then((res) => {
                const clinicsList = [...clinics];
                res.results.map((clinic) => {
                    let exists = clinicsList.find((item) => item.id == clinic.id);
                    console.log(exists);
                    if (!exists)
                        clinicsList.push(clinic);
                })
                setClinics(clinicsList);
                if (res.next)
                    setShouldShowMore(true)
                else
                    setShouldShowMore(false);
            });
    }

    const fetchNextPage = () => {
        setLimit(limit + perPageContent);
    }

    useEffect(() => {
        getClinicsList();
    }, [limit, offset]);

    return (
        <div className="flex-row-1 flex-row-3 results">
          <div className="frame-652">
            <div className="address">122 results</div>
            <div className="frame-651">
              <div className="frame-650">
                <div className="surname inter-medium-eerie-black-14px">Price range</div>
                <div className="frame-96">
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">$10,000 or less</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">$5,000 or less</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">$1,000 or less</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Custom...</div>
                  </article>
                </div>
              </div>
              <div className="frame">
                <div className="clinic-rating inter-medium-eerie-black-14px">Clinic rating</div>
                <div className="frame-96">
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">4 or more</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">3 or more</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">2 or more</div>
                  </article>
                </div>
              </div>
              <div className="frame">
                <div className="spoken-languages inter-medium-eerie-black-14px">Spoken languages</div>
                <div className="frame-96-1 frame-96-3">
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">English</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Polish</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">German</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Spanish</div>
                  </article>
                </div>
              </div>
              <div className="frame">
                <div className="location inter-medium-eerie-black-14px">Location</div>
                <div className="frame-96-1 frame-96-3">
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Premium location</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Close to city center</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Country-side</div>
                  </article>
                </div>
              </div>
              <div className="frame">
                <div className="nearby-accomodation inter-medium-eerie-black-14px">Nearby accomodation</div>
                <div className="frame-96-2 frame-96-3">
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">5-star</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">Boutique hotel</div>
                  </article>
                  <article className="tag-toggle">
                    <div className="title-1 valign-text-middle title-5 body-base">All-inclusive</div>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col">
            <div className="component-2">
              <div className="search-bar">
                <div className="label inter-medium-eerie-black-12px">What</div>
                <div className="frame-587 body-base">
                  <div className="value">Dental implants</div>
                  <div className="placeholder"></div>
                </div>
              </div>
              <img className="line-16" src="img/line-16-5.svg" alt="Line 16" />
              <div className="search-bar">
                <div className="label inter-medium-eerie-black-12px">Where</div>
                <div className="frame-587 body-base">
                  <div className="value">Poland</div>
                  <div className="placeholder"></div>
                </div>
              </div>
              <div className="button-1"><div className="button-2 body-base">Explore</div></div>
            </div>
            {/* <div className="flex-row-2 flex-row-3">
              <div className="button-3">
                <div className="button-4 body-base">Best rated</div>
                <ChevronDown12 className="" color="#000" />
              </div>
              <div className="frame-497">
                <article className="icon-button-1 icon-button-3">
                  <img className="list" src={ListIcon} alt="List" />
                </article>
                <article className="icon-button-2 icon-button-3">
                  <img className="grid" src={GridIcon} alt="Grid" />
                </article>
              </div>
            </div> */}
            <div className="frame-536">
                {clinics.map((clinic) => <ClinicListItem key={clinic.id} clinic={clinic} />)}

        {showMore && <div className="button-5" onClick={() => fetchNextPage()}><div className="button-6 body-base">Show more</div></div>}
            </div>
          </div>
        </div>
    )
}
