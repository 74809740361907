import './style.css';

export const Footer = () => {
    return (
        <div className="frame-612">
          <h1 className="tripmd-1 geist-bold-blue-bayoux-227-2px">
            <span className="span-1 geist-bold-blue-bayoux-227-2px">TRIP</span
            ><span className="span-1 geist-light-blue-bayoux-227-2px">(</span
            ><span className="span-1 geist-bold-blue-bayoux-227-2px">MD</span
            ><span className="span-1 geist-light-blue-bayoux-227-2px">)</span>
          </h1>
          <div className="flex-col-2 flex-col-3">
            <div className="navigation-pill-list-2 navigation-pill-list-3">
              <div className="navigation-pill">
                <div className="title valign-text-middle title-9 inter-medium-blue-bayoux-16px">Treatments</div>
              </div>
              <div className="navigation-pill">
                <div className="title valign-text-middle title-9 inter-medium-blue-bayoux-16px">Destinations</div>
              </div>
              <div className="navigation-pill">
                <div className="title valign-text-middle title-9 inter-medium-blue-bayoux-16px">About us</div>
              </div>
              <div className="navigation-pill">
                <div className="title valign-text-middle title-9 inter-medium-blue-bayoux-16px">Become a partner</div>
              </div>
            </div>
            <div className="frame-612-1 inter-normal-blue-bayoux-16px">
              <div className="title-6 valign-text-middle title-9">Privacy Policy</div>
              <div className="title-7 valign-text-middle title-9">Terms of service</div>
              <p className="title-8 valign-text-middle title-9">© TripMD 2024. All rights reserved.</p>
            </div>
          </div>
        </div>

    );
}
