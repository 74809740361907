/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Globe2 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`globe-2 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_205_57999)">
        <path
          className="path"
          d="M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337M18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699M18.3327 10.0003H1.66602M9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003M9.99935 18.3337C12.0837 16.0517 13.2683 13.0903 13.3327 10.0003C13.2683 6.91035 12.0837 3.94895 9.99935 1.66699M9.99935 18.3337C7.91495 16.0517 6.73039 13.0903 6.66602 10.0003C6.73039 6.91035 7.91495 3.94895 9.99935 1.66699M1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699"
          stroke="#B3B3B3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_205_57999">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
