import React from "react";
import { Link } from "react-router-dom";
import { Award1 } from "../../icons/Award1";
import { ChevronDown6 } from "../../icons/ChevronDown6";
import { ChevronLeft1 } from "../../icons/ChevronLeft1";
import { ChevronRight1 } from "../../icons/ChevronRight1";
import { Columns4 } from "../../icons/Columns4";
import { DollarSign1 } from "../../icons/DollarSign1";
import { Globe1 } from "../../icons/Globe1";
import { Heart4 } from "../../icons/Heart4";
import { Search1 } from "../../icons/Search1";
import image211 from '../../images/image-21-1.png';
import file11 from '../../images/file-1-1.png';
import line165 from '../../images/line-16-5.svg';
import image221 from '../../images/image-22-1.png';
import image274 from '../../images/image-27-4.png';
import image277 from '../../images/image-27-7.png';
import "./style.css";

export const Home = (): JSX.Element => {
  return (
    <div className="home">
        <div className="overlap-group4">
          <div className="frame-25">
            <div className="overlap-group3">
              <img className="overlap-group3-item" src={image211} alt="image 21" />
              <img className="overlap-group3-item" src={file11} alt="file 1" />
              <div className="rectangle-3269"></div>
              <div className="the-right-care-anywhere">The Right Care, Anywhere.</div>
            </div>
          </div>
          <div className="component-2">
            < div className="search-bar">
              <div className="label inter-medium-eerie-black-12px">What</div>
              <div className="frame-587"><div className="placeholder body-base">Search treatments...</div></div>
            </div>
            <img className="line-16" src={line165} alt="Line 16" />
            <div className="search-bar">
              <div className="label inter-medium-eerie-black-12px">Where</div>
              <div className="frame-587 body-base">
                <div className="value"></div>
                <div className="placeholder">Search destinations...</div>
              </div>
            </div>
            <div className="button"><div className="button-1 body-base">Explore</div></div>
          </div>
        </div>
        <p className="discover-world-class discover-world">
          <span className="span0 geist-semi-bold-eerie-black-72px"
            >Discover world-class clinics and healthcare providers in top destinations. Find the right treatment at the
            right price—</span
          ><span className="span1">wherever you want to go.</span>
        </p>
        <div className="frame-459">
          <div className="navigation-pill-list-1 navigation-pill-list-3">
            <div className="navigation-pill-3">
              <div className="ellipse-6-5 ellipse-6-12"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">All</div>
            </div>
            <div className="navigation-pill">
              <div className="ellipse-6"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Dental</div>
            </div>
            <div className="navigation-pill">
              <div className="ellipse-6-6 ellipse-6-12"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Cosmetic</div>
            </div>
            <div className="navigation-pill">
              <div className="ellipse-6-7 ellipse-6-12"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Orthopedic</div>
            </div>
            <div className="navigation-pill">
              <div className="ellipse-6-8 ellipse-6-12"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Fertility</div>
            </div>
            <div className="navigation-pill">
              <div className="ellipse-6-9 ellipse-6-12"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Weight loss</div>
            </div>
            <div className="navigation-pill">
              <div className="ellipse-6-10 ellipse-6-12"></div>
              <div className="title valign-text-middle title-9 inter-medium-eerie-black-16px">Cardiology</div>
            </div>
          </div>
          <div className="frame-458">
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row flex">
                  <div className="ellipse-6-1 ellipse-6-12"></div>
                  <div className="title-1 valign-text-middle title-9 inter-medium-eerie-black-16px">Dental</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Dental Implants</div>
                  <div className="natural-tooth-replacements body-base">Natural tooth replacements</div>
                </div>
                <div className="flex-row-1">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag"><div className="tag-1 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-2"><div className="tag-3 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row-6">
                  <div className="ellipse-6-11 ellipse-6-12"></div>
                  <div className="title-5 valign-text-middle title-9 inter-medium-eerie-black-16px">Cosmetic</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Rhinoplasty</div>
                  <p className="natural-tooth-replacements-1 body-base">Sculpt and reshape your nose</p>
                </div>
                <div className="flex-row-2">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-4"><div className="tag-5 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-6"><div className="tag-7 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row-3">
                  <div className="ellipse-6-2 ellipse-6-12"></div>
                  <div className="title-2 valign-text-middle title-9 inter-medium-eerie-black-16px">Orthopedic</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Knee replacement</div>
                  <p className="natural-tooth-replacements-1 body-base">Restoring mobility and reducing pain</p>
                </div>
                <div className="flex-row-4">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-8"><div className="tag-9 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-10"><div className="tag-11 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row flex">
                  <div className="ellipse-6-1 ellipse-6-12"></div>
                  <div className="title-1 valign-text-middle title-9 inter-medium-eerie-black-16px">Dental</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Veneers</div>
                  <div className="natural-tooth-replacements-1 body-base">Enhance your smile instantly</div>
                </div>
                <div className="flex-row-2">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-12"><div className="tag-13 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-14"><div className="tag-15 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row-5">
                  <div className="ellipse-6-3 ellipse-6-12"></div>
                  <div className="title-3 valign-text-middle title-9 inter-medium-eerie-black-16px">Weight loss</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Gastric sleeve</div>
                  <div className="natural-tooth-replacements-1 body-base">Effective, minimally invasive surgery</div>
                </div>
                <div className="flex-row-4">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-16"><div className="tag-17 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-18"><div className="tag-19 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row-5">
                  <div className="ellipse-6-3 ellipse-6-12"></div>
                  <div className="title-3 valign-text-middle title-9 inter-medium-eerie-black-16px">Weight loss</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Liposuction</div>
                  <p className="natural-tooth-replacements-1 body-base">Body contouring for fat reduction</p>
                </div>
                <div className="flex-row-7">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-20"><div className="tag-21 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-22"><div className="tag-23 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row-3">
                  <div className="ellipse-6-2 ellipse-6-12"></div>
                  <div className="title-2 valign-text-middle title-9 inter-medium-eerie-black-16px">Orthopedic</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Hip resurfacing</div>
                  <p className="natural-tooth-replacements-1 body-base">Alternative to total hip replacement</p>
                </div>
                <div className="flex-row-4">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-24"><div className="tag-25 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-26"><div className="tag-27 body-base">Poland</div></div>
                </div>
              </div>
            </article>
            <article className="frame-46">
              <div className="frame-657">
                <div className="flex-row flex">
                  <div className="ellipse-6-1 ellipse-6-12"></div>
                  <div className="title-1 valign-text-middle title-9 inter-medium-eerie-black-16px">Dental</div>
                </div>
                <div className="frame-646">
                  <div className="dental-implants inter-semi-bold-black-32px">Dental Implants</div>
                  <div className="natural-tooth-replacements body-base">Natural tooth replacements</div>
                </div>
                <div className="flex-row-1">
                  <div className="flex-col flex">
                    <div className="popular-in body-small">Popular in</div>
                    <div className="tag-28"><div className="tag-29 body-base">Mexico</div></div>
                  </div>
                  <div className="tag-30"><div className="tag-31 body-base">Poland</div></div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className="button-2"><div className="button-3 body-base">Show more</div></div>
        <div className="frame-460">
          <div className="overlap-group2">
            <img className="image-22 image" src={image221} alt="image 22" />
            <div className="rectangle-3270"></div>
            <div className="frame-463">
              <div className="affordable-care-global-expertise">Affordable Care, Global Expertise</div>
              <p className="medical-tourism-offe">
                Medical tourism offers patients access to world-class treatments at more affordable prices, often in
                renowned healthcare destinations. Whether you’re seeking specialized surgeries, cosmetic procedures, or
                routine care, traveling abroad can provide greater options and lower costs without compromising quality.
              </p>
            </div>
          </div>
        </div>
        <div className="frame-461">
          <div className="overlap-group1">
            <div className="icon-button">
                <ChevronLeft1 className="icon-instance-node-15" />
            </div>
            <div className="flex-col-1 flex-col-3">
              <div className="discover-world-renow discover-world">Discover World-Renowned Healthcare Destinations</div>
              <div className="frame-478">
                <article className="frame-48">
                  <div className="frame-658">
                    <div className="overlap-group">
                      <img className="image-27 image" src={image274} alt="image 27" />
                      <div className="rectangle-3271"></div>
                      <div className="place inter-semi-bold-white-32px">Poland</div>
                      <div className="title-4 valign-text-middle title-9 inter-medium-white-16px">Europe</div>
                      <div className="frame-466">
                        <div className="navigation-pill-1">
                          <div className="ellipse-6"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Dental</div>
                        </div>
                        <div className="navigation-pill-2">
                          <div className="ellipse-6-4 ellipse-6-12"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Cosmetic</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <article className="frame-48">
                  <div className="frame-658">
                    <div className="overlap-group-1 overlap-group-3">
                      <div className="rectangle-3271"></div>
                      <div className="poland-3 inter-semi-bold-white-32px">Mexico</div>
                      <div className="title-4 valign-text-middle title-9 inter-medium-white-16px">Europe</div>
                      <div className="frame-466">
                        <div className="navigation-pill-1">
                          <div className="ellipse-6"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Dental</div>
                        </div>
                        <div className="navigation-pill-2">
                          <div className="ellipse-6-4 ellipse-6-12"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Cosmetic</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <article className="frame-48">
                  <div className="frame-658">
                    <div className="overlap-group-2 overlap-group-3">
                      <div className="rectangle-3271"></div>
                      <div className="poland-3 inter-semi-bold-white-32px">Turkey</div>
                      <div className="title-4 valign-text-middle title-9 inter-medium-white-16px">Europe</div>
                      <div className="frame-466">
                        <div className="navigation-pill-1">
                          <div className="ellipse-6"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Dental</div>
                        </div>
                        <div className="navigation-pill-2">
                          <div className="ellipse-6-4 ellipse-6-12"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Cosmetic</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <article className="frame-48">
                  <div className="frame-658">
                    <div className="overlap-group">
                      <img className="image-27 image" src={image277} alt="image 27" />
                      <div className="rectangle-3271"></div>
                      <div className="poland-3 inter-semi-bold-white-32px">Indonesia</div>
                      <div className="title-4 valign-text-middle title-9 inter-medium-white-16px">Europe</div>
                      <div className="frame-466">
                        <div className="navigation-pill-1">
                          <div className="ellipse-6"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Dental</div>
                        </div>
                        <div className="navigation-pill-2">
                          <div className="ellipse-6-4 ellipse-6-12"></div>
                          <div className="title valign-text-middle title-9 inter-medium-white-16px">Cosmetic</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="icon-button-1">
                <ChevronRight1 className="" />
            </div>
          </div>
        </div>
        <div className="frame-607">
          <div className="frame-420">
            <p className="your-trusted-partner geist-semi-bold-eerie-black-72px">
              Your Trusted Partner for Medical Care Abroad
            </p>
            <p className="we-make-finding-the inter-medium-eerie-black-24px">
              We make finding the right treatment abroad easy. Compare top clinics and book with confidence.
            </p>
          </div>
          <div className="frame-71">
            <div className="frame">
                <Award1 className="icon-instance-node-16" />
              <div className="frame-60">
                <div className="trusted-clinics inter-medium-eerie-black-24px">Trusted Clinics</div>
                <p className="work-with-internatio body-base">
                  Work with internationally accredited hospitals and experienced medical teams.
                </p>
              </div>
            </div>
            <div className="frame">
              <Globe1 className="icon-instance-node-16" />
              <div className="frame-60">
                <div className="global-destinations inter-medium-eerie-black-24px">Global Destinations</div>
                <p className="choose-from-top-heal body-base">
                  Choose from top healthcare destinations tailored to your treatment needs.
                </p>
              </div>
            </div>
            <div className="frame">
              <Search1 className="icon-instance-node-16" />
              <div className="frame-60">
                <div className="personalized-search inter-medium-eerie-black-24px">Personalized Search</div>
                <p className="find-treatments-des body-base">
                  Find treatments, destinations, and clinics that fit your health and travel preferences.
                </p>
              </div>
            </div>
            <div className="frame">
              <DollarSign1 className="icon-instance-node-16" />
              <div className="frame-60">
                <div className="transparent-pricing inter-medium-eerie-black-24px">Transparent Pricing</div>
                <p className="compare-costs-upfron body-base">
                  Compare costs upfront with no hidden fees for peace of mind.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-608">
          <div className="faq geist-semi-bold-eerie-black-72px">FAQ</div>
          <div className="frame-426 inter-medium-eerie-black-16px">
            <div className="frame-1">
              <p className="what-is-medical-tour">What is medical tourism, and how does it work?</p>
              <ChevronDown6 className="chevron-down" />
            </div>
            <div className="frame-1">
              <p className="how-do-i-choose-the">How do I choose the right clinic for my treatment abroad?</p>
              <ChevronDown6 className="chevron-down" />
            </div>
            <div className="frame-1">
              <p className="are-the-healthcare-p">Are the healthcare providers internationally accredited?</p>
              <ChevronDown6 className="chevron-down" />
            </div>
            <div className="frame-1">
              <p className="how-can-i-compare-th">How can I compare the cost of treatments across different countries?</p>
              <ChevronDown6 className="chevron-down" />
            </div>
            <div className="frame-1">
              <p className="is-it-safe-to-travel">Is it safe to travel for medical procedures during recovery?</p>
              <ChevronDown6 className="chevron-down" />
            </div>
            <div className="frame-1">
              <p className="whats-included-in-t">What’s included in the treatment packages or booking process?</p>
              <ChevronDown6 className="chevron-down" />
            </div>
          </div>
        </div>
    </div>
  );
};
