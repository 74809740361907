/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const DollarSign1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`dollar-sign-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_206_69027)">
        <path
          className="path"
          d="M16 1.33301V30.6663M22.6667 6.66634H12.6667C11.429 6.66634 10.242 7.15801 9.36684 8.03318C8.49167 8.90835 8 10.0953 8 11.333C8 12.5707 8.49167 13.7577 9.36684 14.6328C10.242 15.508 11.429 15.9997 12.6667 15.9997H19.3333C20.571 15.9997 21.758 16.4913 22.6332 17.3665C23.5083 18.2417 24 19.4287 24 20.6663C24 21.904 23.5083 23.091 22.6332 23.9662C21.758 24.8413 20.571 25.333 19.3333 25.333H8"
          stroke="#51617B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_206_69027">
          <rect className="rect" fill="white" height="32" width="32" />
        </clipPath>
      </defs>
    </svg>
  );
};
