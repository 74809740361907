/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Globe1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`globe-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M29.3337 16.0003C29.3337 23.3641 23.3641 29.3337 16.0003 29.3337M29.3337 16.0003C29.3337 8.63653 23.3641 2.66699 16.0003 2.66699M29.3337 16.0003H2.66699M16.0003 29.3337C8.63653 29.3337 2.66699 23.3641 2.66699 16.0003M16.0003 29.3337C19.3354 25.6825 21.2307 20.9443 21.3337 16.0003C21.2307 11.0564 19.3354 6.31813 16.0003 2.66699M16.0003 29.3337C12.6653 25.6825 10.77 20.9443 10.667 16.0003C10.77 11.0564 12.6653 6.31813 16.0003 2.66699M2.66699 16.0003C2.66699 8.63653 8.63653 2.66699 16.0003 2.66699"
        stroke="#51617B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
