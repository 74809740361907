import { Heart4 } from "../../icons/Heart4";
import { Columns4 } from "../../icons/Columns4";
import { ThumbsUp } from '../../icons/ThumbsUp';
import { Award13 } from '../../icons/Award13';
import { MessageSquare1 } from '../../icons/MessageSquare1';
import { useNavigate } from 'react-router-dom';

export const ClinicListItem = ({ clinic }) => {
    const image = clinic.images && clinic.images[0];
    const styling = {}
    if (image) {
        styling.backgroundImage = 'url(' + image + ')';
    }
    const navigate = useNavigate();

    return (
        <div className="frame-481" onClick={() => navigate('/clinics/' + clinic.id)}>
                  <div className="flex-row">
                    <div className="button-container" style={styling}>
                        <div className="button">
                            <Columns4 className="clinic-list-icon" color="#000" />
                          <div className="compare inter-medium-eerie-black-16px">Compare</div>
                        </div>
                      <div className="icon-button">
                        <Heart4 color="#000" className="clinic-list-icon clinic-list-heart-icon" />
                      </div>
                    </div>
                    <div className="frame-container-1">
                      <div className="frame-495">
                        <div className="frame-494-1">
                          <div className="warsaw-poland valign-text-middle inter-medium-sonic-silver-16px">
                            Warsaw, Poland
                          </div>
                          <div className="warsaw-dental-clinic heading">{clinic.name}</div>
                        </div>
                        <div className="frame-488">
                        {clinic.highlights?.map((highlight) => (
                            <div className="frame-485">
                                <ThumbsUp className="" />
                                <div className="premium-location inter-medium-eerie-black-14px">Premium location</div>
                            </div>))}
                        </div>
                      </div>
                      <div className="frame-487">
                        <div className="frame-485">
                            <MessageSquare1 className="" />
                          <div className="english-german-polish body-small">{clinic.languages ? clinic.languages?.join(', ') : 'Languages info not available'}</div>
                        </div>
                        <div className="frame-486">
                            <Award13 className="" />
                          <p className="american-dental-asso body-small">{clinic.accreditions?.join(', ')} {clinic.accreditions ? 'accredited' : 'No accreditions'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="frame-493">
                      <div className="frame-492">
                        <div className="frame-491"><div className="text-1 inter-semi-bold-sherwood-green-20px">4.8</div></div>
                        <div className="frame-346">
                          <div className="address-1 inter-normal-sonic-silver-12px">17 reviews</div>
                          <div className="excellent-5 valign-text-middle inter-medium-sherwood-green-16px">Excellent</div>
                        </div>
                      </div>
                      <div className="frame-43">
                        <div className="recovery-5 inter-normreal-sonic-silver-12px">Recovery</div>
                        <div className="address-2 valign-text-middle inter-medium-eerie-black-16px">6 days</div>
                      </div>
                      <div className="frame-44">
                        <div className="starting-at inter-normal-sonic-silver-12px">Starting at</div>
                        <div className="price-5 valign-text-middle inter-medium-eerie-black-16px">$8,999</div>
                      </div>
                    </div>
                  </div>
                </div>
    )
}
