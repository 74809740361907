/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Award3 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`award-3 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_205_57891)">
        <path
          className="path"
          d="M6.84102 11.5747L5.83268 19.1663L9.99935 16.6663L14.166 19.1663L13.1577 11.5663M15.8327 6.66634C15.8327 9.888 13.221 12.4997 9.99935 12.4997C6.77769 12.4997 4.16602 9.888 4.16602 6.66634C4.16602 3.44468 6.77769 0.833008 9.99935 0.833008C13.221 0.833008 15.8327 3.44468 15.8327 6.66634Z"
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_205_57891">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
