import React from "react";
import { RouterProvider, createBrowserRouter, BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./screens/Home";
import { ClinicsList } from './screens/ClinicsList';
import Navbar from './components/Navbar';
import { Footer } from './components/Footer';
import { Clinic } from './screens/Clinic';
import { GlobalProvider } from './contexts/global';

const router = createBrowserRouter([
  {
      element: <Navbar />,
      children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: '/clinics',
            element: <ClinicsList />
          },
          {
            path: '/clinic',
            element: <Clinic />
          }
      ]
}]);

const App  = () => {
  return (
    <GlobalProvider>
        <BrowserRouter>
            <div className="container-center-horizontal">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/clinics" element={<ClinicsList />} />
                    <Route path="/clinics/:id" element={<Clinic />} />
                </Routes>
                <Navbar />
            </div>
            <Footer />
        </BrowserRouter>
    </GlobalProvider>
  )
};

export default App;

