/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const ThumbsUp = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`thumbs-up ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_205_57843)">
        <path
          className="path"
          d="M5.83268 9.16699L9.16602 1.66699C9.82906 1.66699 10.4649 1.93038 10.9338 2.39923C11.4026 2.86807 11.666 3.50395 11.666 4.16699V7.50033H16.3827C16.6243 7.49759 16.8636 7.54741 17.084 7.64634C17.3044 7.74527 17.5007 7.89094 17.6592 8.07325C17.8178 8.25557 17.9348 8.47018 18.0021 8.7022C18.0695 8.93423 18.0856 9.17812 18.0493 9.41699L16.8993 16.917C16.8391 17.3144 16.6372 17.6767 16.3309 17.937C16.0247 18.1973 15.6346 18.3382 15.2327 18.3337H5.83268M5.83268 9.16699V18.3337M5.83268 9.16699H3.33268C2.89065 9.16699 2.46673 9.34259 2.15417 9.65515C1.84161 9.96771 1.66602 10.3916 1.66602 10.8337V16.667C1.66602 17.109 1.84161 17.5329 2.15417 17.8455C2.46673 18.1581 2.89065 18.3337 3.33268 18.3337H5.83268"
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_205_57843">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
