/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Star26 = ({
  color = "#757575",
  className,
}: Props): JSX.Element => {
  return (
    <svg
      className={`star-26 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.99967 1.33301L10.0597 5.50634L14.6663 6.17967L11.333 9.42634L12.1197 14.013L7.99967 11.8463L3.87967 14.013L4.66634 9.42634L1.33301 6.17967L5.93967 5.50634L7.99967 1.33301Z"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </svg>
  );
};

Star26.propTypes = {
  color: PropTypes.string,
};
