import { useContext, createContext, useState, useEffect } from 'react';
import { getTreatments } from '../api/clinics';


const GlobalContext = createContext(null);

export const GlobalProvider = ({ children }) => {
    const [treatments, setTreatments] = useState([]);

    const getTreatmentsList = () => {
        getTreatments()
            .then((response) => {
                console.log(response);
                setTreatments(response);
            });
    }

    useEffect(() => {
        getTreatmentsList();
    }, []);

    return (
        <GlobalContext.Provider
            value={{ treatments }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobal = () => {
    return useContext(GlobalContext);
}
